import React from "react"
import Header from "../components/header";

const AboutPage = () => {
    return (
        <div>
            <Header/>
            <p>About vefside.com</p>
        </div>
    )
};

export default AboutPage;